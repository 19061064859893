<template>
  <!-- Version: 1.2 -->
  <h2>1. Описание игры</h2>
  <p>
    Игра IIHF World Championship от компании Highlight Games является уникальной программной имитацией произвольно
    выбранных лучших моментов из матчей по хоккею с шайбой для создания игровых возможностей.
  </p>
  <h2>2. Продолжительность и содержание игры</h2>
  <p>
    Один раунд электронной игры, в которой делаются ставки, длится в среднем 5 минут и включает в себя анонс матча для
    этой игры, показ и результаты.
  </p>
  <p>
    Подборка лучших турниров по хоккею с шайбой представляет собой независимую, произвольную и непредсказуемую
    статистическую выборку, создаваемую генератором случайных чисел.
  </p>
  <h2>3. Рынки ставок</h2>
  <h3>3.1 Ставка на точное число голов (включая овертайм)</h3>
  <p>
    Смысл ставки на точное число голов заключается в прогнозе точного количества заброшенных шайб в одном матче. Таким
    образом, этот вариант ставки означает, что вам необходимо предсказать точное число голов, невзирая на окончательный
    исход всего матча.
  </p>
  <h3>3.2 Ставка на точное число голов команды хозяев (включая овертайм)</h3>
  <p>Ставка на точное число голов, которые забьет в игре команда хозяев.</p>
  <h3>3.3 Ставка на точное число голов выездной команды (включая овертайм)</h3>
  <p>Ставка на точное число голов, которые забьет в игре выездная команда.</p>
  <h3>3.4 Ставка «Обе забьют» (основное время)</h3>
  <p>
    Чтобы выиграть ставку «Обе забьют», обеим командам необходимо забросить хотя бы по одной шайбе, то есть любой
    результат начиная со счета 1-1 и выше является выигрышной ставкой. Ставка «Обе забьют» дает бетторам шанс извлечь
    преимущество из таких событий с целью получения выгодного коэффициента, когда только одна из двух команд смогла
    забить гол.
  </p>
  <h3>3.5 Ставка на правильный счет (включая овертайм)</h3>
  <p>
    Ставка на правильный счет – это заключение пари на окончательный исход матча в хоккее с шайбой, при этом в игре есть
    18 возможных исходов с максимальным числом 5 голов.
  </p>
  <h3>3.6 Ставка «Исход матча» (1X2) (основное время)</h3>
  <p>Ставка на победу хозяев поля (1), победу выездной команды (2) или завершение матча вничью (X).</p>
  <h3>3.7 Ставка «Исход матча» (1,2) (включая овертайм)</h3>
  <p>Ставка на победу хозяев поля (1), победу выездной команды (2).</p>
  <h3>3.8 Ставка «Исход матча и больше/меньше 3.5 голов» (основное время)</h3>
  <p>Ставка на исход матча и на то, что общее число голов будет меньше или больше 3.5 гола.</p>
  <h3>3.9 Ставка «Исход матча и больше/меньше 3.5 голов» (включая овертайм)</h3>
  <p>Ставка на исход матча и на то, что общее число голов будет меньше или больше 3.5 гола.</p>
  <h3>3.10 Ставка «Число голов команды хозяев или выездной команды больше/меньше 2.5» (включая овертайм)</h3>
  <p>Ставка на то, что команда хозяев или выездная команда забьет либо больше, либо меньше, чем 2.5 гола.</p>
  <h3>3.11 Ставка «Общее число голов больше 2.5, 3.5, 4.5» (включая овертайм)</h3>
  <p>Ставка на то, что общее число голов, забитое обеими командами в матче, будет больше, чем 2.5, 3.5 или 4.5.</p>
  <h3>3.12 Линии ставок с 2-мя исходами (основное время)</h3>
  <p>
    Линия ставок с установленными коэффициентами для определения команды-фаворита или более слабой команды в матче.
    Такой гандикап дает одной из 2-х команд преимущество в «половину гола», при этом есть 2 возможных исхода.
  </p>
  <h3>3.13 Линии ставок с 3-мя исходами (основное время)</h3>
  <p>
    Линия ставок с установленными коэффициентами для определения команды-фаворита или более слабой команды в матче.
    Такой гандикап дает одной из 2-х команд преимущество в «целый гол», при этом есть 3 возможных исхода (включая
    ничью).
  </p>
  <h3>3.14 Линии ставок «Самый результативный период» (основное время)</h3>
  <p>
    Ставка на то, в какой период будет заброшено наибольшее число шайб. Возможные варианты: период 1, период 2, период 3
    или ничья.
  </p>
  <h3>3.15 Ставка «Кто выиграет больше периодов» (основное время)</h3>
  <p>
    Ставка на то, какая команда выиграет наибольшее количество периодов. Возможные варианты: команда хозяев, выездная
    команда или ничья.
  </p>
  <h3>3.16 Ставка «Какая команда забьет первой?» (включая овертайм)</h3>
  <p>Ставка на то, какая команда забьет первый гол.</p>
</template>
